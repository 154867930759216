var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footers", staticStyle: { display: "none" } },
        [
          _vm._l(_vm.links, function (item) {
            return _c(
              "el-link",
              {
                key: item.key,
                staticClass: "mr15 mb20",
                attrs: { href: item.href, target: "_blank" },
              },
              [_vm._v(_vm._s(item.title))]
            )
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "title mb15",
            domProps: { textContent: _vm._s(_vm.copyright) },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }